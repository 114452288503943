import { useLayoutEffect } from 'react'

const isOdd = (n) => Math.abs(n % 2) === 1

const isEven = (n) => n % 2 === 0

const numericPredicate = (a, b) => {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

const STATES = {
  LOADING: Symbol('LOADING'),
  SUCCESS: Symbol('SUCCESS'),
  FAILURE: Symbol('FAILURE'),
}

const PAGE_STATES = {
  VIDEO: 'VIDEO',
  PHOTO: 'PHOTO',
  HOME: 'HOME',
}

const MENU_SPRITES = [
  {
    position: 0,
    width: 50.78,
    text: 'Home',
  },
  {
    position: 1,
    width: 45.41,
    text: 'Work',
  },
  {
    position: 2,
    width: 45,
    text: 'Films',
  },
  {
    position: 3,
    width: 109.47,
    text: 'Photography',
  },
]

const MENU_STATES = {
  HOME: {
    spriteIndex: 1,
    sprite: MENU_SPRITES[1],
  },
  VIDEO: {
    spriteIndex: 2,
    sprite: MENU_SPRITES[2],
  },
  PHOTO: {
    spriteIndex: 3,
    sprite: MENU_SPRITES[3],
  },
}

const ABOUT_SPRITES = [
  {
    position: 0,
    width: 52,
    text: 'About',
  },
  {
    position: 1,
    width: 47.85,
    text: 'Close',
  },
]

const ABOUT_STATES = {
  ABOUT: {
    spriteIndex: 0,
    sprite: ABOUT_SPRITES[0],
  },
  CLOSE_ABOUT: {
    spriteIndex: 1,
    sprite: ABOUT_SPRITES[1],
  },
  CLOSE_LIGHTBOX: {
    spriteIndex: 1,
    sprite: ABOUT_SPRITES[1],
  },
}

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, []) // Empty array ensures effect is only run on mount and unmount
}

export {
  useLockBodyScroll,
  numericPredicate,
  isOdd,
  isEven,
  STATES,
  PAGE_STATES,
  MENU_SPRITES,
  MENU_STATES,
  ABOUT_SPRITES,
  ABOUT_STATES,
}
