import React from 'react'

const Diamond = () => {
  return (
    <svg
      style={{ minWidth: '9', minHeight: '9' }}
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="3" fill="white" />
    </svg>
  )
}

export default Diamond
