import React from 'react'
import THEME from './theme'
import Diamond from './Diamond'

const StickyList = (props) => {
  const listOffset =
    props.projects.length < 8
      ? props.projects.length
      : THEME.list.itemHeight * 8

  return (
    <div class="StickyList">
      <div class="InnerStickyList">
        {
          <div
            class="StickyListSubHeading"
            style={{
              marginBottom: listOffset + 'px',
            }}>
            {
              //props.header
            }
          </div>
        }

        <ul
          style={{
            marginTop: -listOffset + 'px',
          }}>
          {props.projects.map((project, index) => {
            const id = project.sys.id
            return (
              <li
                key={`projectListItem-${id}`}
                onClick={() => props.openProject(project)}
                onMouseEnter={() => props.setCursor(index)}
                onMouseLeave={() => props.setCursorActive(false)}
                style={{ cursor: 'pointer' }}>
                <div
                  class="ProjectListItem"
                  style={{
                    borderTop: '1px solid white',
                    opacity: props.cursorActive === false ? 1 : 0.6,
                    mixBlendMode: 'exclusion',
                    //   backgroundColor:
                    //     props.cursorActive === false
                    //       ? 'transparent'
                    //       : props.cursor === index
                    //       ? 'white'
                    //       : 'transparent',
                  }}
                />
                <div
                  className="ProjectListItemInner"
                  style={{
                    // borderRadius: '4px',
                    // mixBlendMode: 'darken',
                    // backgroundColor: '#000',
                    mixBlendMode:
                      props.cursorActive === false
                        ? 'exclusion'
                        : props.cursor === index
                        ? 'lighten'
                        : 'exclusion',
                    opacity:
                      props.cursorActive === false
                        ? 1
                        : props.cursor === index
                        ? 1
                        : 0.8,
                  }}>
                  <div class="ProjectClient">{project.fields.client}</div>

                  <div class="flex">
                    <Diamond />
                    <div class="ProjectTitle">
                      {project.fields.projectTitle}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
export default StickyList
