import React from 'react'
import Diamond from './Diamond'
import THEME from './theme'
import { useSwipeable, Swipeable } from 'react-swipeable'

const LightBox = (props) => {
  return (
    <div
      style={{
        zIndex: THEME.z.lightBox,
        position: 'fixed',
        transform: props.isOpen ? 'translateX(0px)' : 'translateX(110vw)',
        opacity: props.isOpen ? '1' : '0',
        transition: 'opacity 300ms',
        transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
      }}>
      <Swipeable
        onSwipedLeft={props.nextInGallery}
        onSwipedRight={props.prevInGallery}>
        <div
          style={{
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
          }}>
          <div
            style={{
              display: 'flex',
              transform: `translateX(${props.lightBoxIndex * -100}vw)`,
              transition: '400ms transform',
            }}>
            {props.project === null ? (
              <div />
            ) : (
              props.project.fields.assets.map((asset, index) => {
                const type = asset.fields.file.contentType

                if (typeof asset.fields.description !== 'undefined') {
                  if (
                    asset.fields.description.match(
                      /https:\/\/(?:player.)?(?:(vimeo).com\/(.*))/,
                    )
                  ) {
                    return (
                      <div
                        style={{
                          width: '100vw',
                          minWidth: '100vw',
                          height: '100vh',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <iframe
                          color="black"
                          title="vimeo-player"
                          src={`${asset.fields.description}?color=666666&title=0&byline=0&portrait=0`}
                          width="80%"
                          height="80%"
                          frameborder="0"
                          allowfullscreen></iframe>
                      </div>
                    )
                  }
                }

                if (type === 'video/mp4') {
                  return (
                    <div
                      style={{
                        width: '100vw',
                        minWidth: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <video
                        playsinline="playsinline"
                        className="lightboxVideo"
                        autoPlay
                        muted
                        style={{
                          opacity: index === props.lightBoxIndex ? 1 : 0,
                          transition: '800ms opacity',

                          objectFit: 'cover',
                          maxHeight: '85vh',
                        }}>
                        <source src={asset.fields.file.url} type={type} />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )
                }
                if (
                  type === 'image/png' ||
                  type === 'image/jpeg' ||
                  type === 'image/gif'
                ) {
                  return (
                    <div
                      style={{
                        width: '100vw',
                        minWidth: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <img
                        alt=""
                        className="lightboxImage"
                        style={{
                          opacity: index === props.lightBoxIndex ? 1 : 0,
                          transition: '300ms opacity',

                          height: 'auto',
                          maxHeight: '75vh',
                        }}
                        src={asset.fields.file.url}
                      />
                    </div>
                  )
                }

                return null
              })
            )}
          </div>
          <div id="GalleryButtonBox">
            <GalleryButton
              className="GalleryButton LeftButton"
              style={{
                position: 'absolute',
                opacity: props.isFirst ? '0.0' : '1',
                pointerEvents: props.isFirst ? 'none' : 'auto',
              }}
              onClick={props.prevInGallery}
            />

            <GalleryButton
              className="GalleryButton RightButton"
              style={{
                position: 'absolute',
                transform: 'rotate(180deg)',
                opacity: props.isLast ? '0.0' : '1',
                pointerEvents: props.isLast ? 'none' : 'auto',
              }}
              onClick={props.nextInGallery}
            />
          </div>

          {props.project ? (
            <div id="LightboxTitleWrapper">
              <div id="LightboxTitleContainer">
                <div class="ProjectClient">{props.project.fields.client}</div>
                <div class="flex">
                  <Diamond />
                  <div class="ProjectTitle">
                    {props.project.fields.projectTitle}
                  </div>
                </div>
              </div>
              {props.project.fields.type === 'Photography' ? (
                <div id="LightboxDescription">
                  {
                    props.project.fields.assets[props.lightBoxIndex].fields
                      .description
                  }
                </div>
              ) : null}
            </div>
          ) : (
            <div />
          )}
        </div>
      </Swipeable>
    </div>
  )
}

const Arrow = () => {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 90 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <line
        y1="-0.5"
        x2="89.1"
        y2="-0.5"
        transform="matrix(-1 0 0 1 90 37.1445)"
        stroke="white"
        vectorEffect="non-scaling-stroke"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M33.3 72.6445L0.900051 36.6445L33.3 0.644531"
        stroke="white"
      />
    </svg>
  )
}

const GalleryButton = (props) => {
  return (
    <button
      className={props.className}
      onClick={() => {
        props.onClick()
      }}
      style={{
        background: 'transparent',
        ...props.style,
      }}>
      <Arrow />
    </button>
  )
}

export default LightBox
