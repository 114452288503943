import React from 'react'
import THEME from './theme'
import logo from './Logo.png'

const Christelle = (props) => {
  const clientWidthRatio = 1036 / 1440
  const centerX = props.clientWidth / 2
  const centerY = props.clientHeight / 2

  const start = {
    x: centerX - props.clientWidth * clientWidthRatio * 0.5,
    y: centerY - 68 / 2,
    // w: (props.clientWidth * clientWidthRatio),
    // h: 68,
    s: (clientWidthRatio * props.clientWidth) / 303,
  }

  const end = {
    x: 50,
    y: 40,
    // w: 303,
    // h: 20,
    s: 0.9,
  }

  return (
    <img
      src={logo}
      alt="xtelle"
      style={{
        mixBlendMode: props.retract ? 'normal' : 'exclusion',
        position: 'fixed',
        top: '0px',
        left: '0px',
        maxWidth: '303px',
        transformOrigin: 'top left',
        zIndex: THEME.z.xtelle,
        transition: 'transform 300ms',
        transform: !props.retract
          ? `
            translate3d(${start.x}px, ${start.y}px, 0px)  scale3d(${start.s}, ${start.s}, 1)
            `
          : `
            translate3d(${end.x}px, ${end.y}px, 0px)  scale3d(${end.s}, ${end.s}, 1)
            `,
      }}
    />
  )
}

export default Christelle
