import React from 'react'
import THEME from './theme'
import { PAGE_STATES } from './lib'
// const noop = () => {}

class VideoBackground extends React.Component {
  applyRef(id, ref) {
    this[`${id}`] = ref
    // const splashId = this.props.splash.sys.id
    // if (id === splashId) {
    //   this[splashId].play()
    // }
  }

  play(ref) {
    this[ref].play()
  }

  pause(ref) {
    this[ref].pause()
  }

  render() {
    const { props } = this


    const splashSrc = props.splash.fields?.preview?.fields.file.url
    const splashId = props.splash.sys.id

    if (this[splashId]) {
      this.play(splashId)
    }

    return (
      <div
        id="VideoBackground"
        style={{
          ...props.style,
        }}>
        <div id="VideoBackgroundInner">
          <video
            playsinline="playsinline"
            class="Video"
            key={`video-bg-${splashId}`}
            ref={this.applyRef.bind(this, splashId)}
            style={{
              opacity:
                props.pagePosition === PAGE_STATES.HOME &&
                props.videoCursor === null
                  ? 1
                  : 0,
              zIndex:
                props.pagePosition === PAGE_STATES.HOME &&
                props.videoCursor === null
                  ? 11
                  : 0,
            }}
            loop
            muted
            preload="none"
            onLoadStart={() => this.play(splashId)}
            // onLoad={() => console.log(`Loaded: ${src}`)}
          >
            <source src={splashSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {props.projects.map((project, index) => {
            const id = project.sys.id

            if (this[id]) {
              if (
                index === props.videoCursor &&
                props.pagePosition === PAGE_STATES.HOME
              ) {
                this.play(id)
                // console.log('play')
              } else if (!props.videoCursorActive) {
                this.pause(id)
                // console.log('pause')
              } else if (index === props.videoCursor) {
                this.play(id)
                // console.log('play')
              } else {
                this.pause(id)
                // console.log('pause')
              }
            }
            
            const src = project.fields?.preview?.fields.file.url
           
            return (
              <video
                playsinline="playsinline"
                class="Video"
                key={`video-bg-${id}`}
                ref={this.applyRef.bind(this, id)}
                style={{
                  opacity: index === props.videoCursor ? 1 : 0,
                  zIndex: index === props.videoCursor ? 10 : 0,
                }}
                loop
                muted
                preload="none"
                // onLoadStart={() => console.log(`Loaded: ${src}`)}
                // onLoad={() => console.log(`Loaded: ${src}`)}
              >
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
          })}
        </div>
        {
          //   <div
          //   id="VideoOverlay"
          //   style={{
          //     opacity: props.videoCursorActive ? '1' : '0',
          //     // opacity: overlayOn ? 1 : 0,
          //   }}
          // />
        }
      </div>
    )
  }
}

export default VideoBackground
