import React from 'react'
import THEME from './theme'
import { isOdd, isEven } from './lib'

class PhotoBackground extends React.Component {
  constructor(props) {
    super(props)
    this.listItems = []
    this.setRef = this.setRef.bind(this)
  }

  setRef(ref, projectIndex) {
    this.listItems[projectIndex] = ref
    return true
  }

  render() {
    const { props } = this
    const projects = props.projects.map((project, index) => {
      return {
        ...project,
        _index: index,
      }
    })

    const listA = projects.filter((item, index) => isOdd(index))
    const listB = projects.filter((item, index) => isEven(index))

    let y
    if (typeof this.listItems[props.cursor] !== 'undefined') {
      y = this.listItems[props.cursor].offsetTop
    } else {
      y = 0
    }

    return (
      <div id="PhotoBackground">
        <div id="InnerPhotoBackground">
          <div
            id="PhotoColumnContainer"
            style={{
              transform: `translate3d(0px, ${-y + 100}px, 0px)`,
            }}>
            <PhotoColumn
              left
              {...props}
              setRef={this.setRef}
              listItems={listB}
            />
            <PhotoColumn {...props} setRef={this.setRef} listItems={listA} />
          </div>
        </div>
      </div>
    )
  }
}

const PhotoColumn = (props) => {
  return (
    <div class="PhotoColumn">
      {props.listItems.map((project) => {
        const src = project.fields.assets[1].fields.file.url

        const id = project.sys.id

        const projectIndex = project._index

        return (
          <div
            class={
              props.left
                ? 'PhotoItem PhotoItemLeft'
                : 'PhotoItem PhotoItemRight'
            }
            key={`photo-${id}`}
            ref={(ref) => {
              props.setRef(ref, projectIndex)
            }}>
            <img
              alt=""
              class="PhotoImage"
              style={{
                // filter:
                //   !props.cursorActive || props.cursor !== projectIndex
                //     ? 'url(#monochrome)'
                //     : 'none',
                opacity:
                  !props.cursorActive || props.cursor === projectIndex
                    ? 0.8
                    : 0.1,
              }}
              src={src}
            />
          </div>
        )
      })}
    </div>
  )
}

export default PhotoBackground
