import React from 'react'
import THEME from './theme'
import {
  PAGE_STATES,
  MENU_SPRITES,
  MENU_STATES,
  ABOUT_SPRITES,
  ABOUT_STATES,
} from './lib'

const rightOffset = 140

const Header = (props) => {
  const currentMenuSpriteIndex = MENU_STATES[props.pagePosition].spriteIndex

  const currentMenuSprite = MENU_STATES[props.pagePosition].sprite

  const menuButtonWidth = currentMenuSprite.width

  const currentAboutSpriteIndex = ABOUT_STATES[props.aboutPosition].spriteIndex

  return (
    <>
      <SpriteButton
        id="AboutControl"
        style={{
          zIndex: THEME.z.aboutButton,
          // top: props.aboutOpen || props.lightBoxOpen ? 0 : 50,
          transition: 'top 200ms',
          // position: 'fixed',
          // right: THEME.page.margin + 'px',
          // top: 0 + 'px',
          mixBlendMode: 'lighten',
        }}
        onClick={
          props.aboutPosition === 'CLOSE_ABOUT'
            ? () => props.closeAbout()
            : props.aboutPosition === 'CLOSE_LIGHTBOX'
            ? () => props.closeProject()
            : () => props.openAbout()
        }
        index={0}
        sprites={ABOUT_SPRITES}
        spriteIndex={currentAboutSpriteIndex}
        height={100}
      />

      <menu
        id="MenuControl"
        style={{
          // top: 50,
          zIndex: THEME.z.menu,
          backgroundColor: 'black',
          height: 100 + 'px',
          margin: 0,
          mixBlendMode: 'lighten',
          width: rightOffset + menuButtonWidth + 'px',
          // transition: 'width 200ms',
          transition: 'transform 200ms, width 200ms, opacity 200ms, top 200ms',
          transform: props.aboutOpen ? 'translateX(-200px)' : 'translateX(0px)',
          opacity: props.aboutOpen ? '0' : '1',
        }}>
        <Arrow
          style={{
            transform:
              props.menuOpen === true ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />

        <SpriteButton
          style={{
            zIndex: THEME.z.menuButton,
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          onClick={props.toggleMenu}
          index={0}
          sprites={MENU_SPRITES}
          spriteIndex={currentMenuSpriteIndex}
          height={100}
        />

        <div
          style={{
            position: 'absolute',
            left: 0 + 'px',
            width: rightOffset + menuButtonWidth + 'px',
            height: 75 + 'px',
          }}>
          <Rule
            style={{
              width: rightOffset + menuButtonWidth + 'px',
              transform: props.menuOpen
                ? 'translateY(75px)'
                : 'translateY(37px)',
              opacity: props.menuOpen ? '1' : '0',
              transition: '200ms transform, 200ms opacity',
            }}
          />

          <DropdownButton
            style={{
              width: menuButtonWidth + 'px',
              opacity: props.menuOpen ? '1' : '0',
              transform: props.menuOpen
                ? 'translateY(76px)'
                : 'translateY(0px)',
            }}
            onClick={() => {
              if (props.pagePosition === PAGE_STATES.HOME) props.goToFilms()
              if (props.pagePosition === PAGE_STATES.VIDEO) props.goToTop()
              if (props.pagePosition === PAGE_STATES.PHOTO) props.goToFilms()
            }}
            text={props.pagePosition === PAGE_STATES.VIDEO ? 'Home' : 'Films'}
          />

          <Rule
            style={{
              width: rightOffset + menuButtonWidth + 'px',
              transform: props.menuOpen
                ? 'translateY(150px)'
                : 'translateY(37px)',
              opacity: props.menuOpen ? '1' : '0',
              transition: '200ms transform, 200ms opacity',
            }}
          />

          <DropdownButton
            style={{
              pointerEvents: props.menuOpen ? 'auto' : 'none',

              width: rightOffset + menuButtonWidth + 'px',
              opacity: props.menuOpen ? '1' : '0',
              transform: props.menuOpen
                ? 'translateY(152px)'
                : 'translateY(0px)',
            }}
            onClick={() => {
              if (props.pagePosition === PAGE_STATES.HOME) props.goToPhotos()
              if (props.pagePosition === PAGE_STATES.VIDEO) props.goToPhotos()
              if (props.pagePosition === PAGE_STATES.PHOTO) props.goToTop()
            }}
            text={
              props.pagePosition === PAGE_STATES.PHOTO ? 'Home' : 'Photography'
            }
          />
        </div>
      </menu>
    </>
  )
}

const SpriteButton = (props) => {
  const x = props.sprites
    .slice(0, props.spriteIndex)
    .reduce((acc, sprite) => acc + sprite.width + 16, 0)

  const activeSprite = props.sprites[props.spriteIndex]

  return (
    <button
      style={{
        backgroundColor: 'transparent',
        margin: 0,
        padding: 0,
        transition: 'transform 200ms, width 200ms',
        height: props.height + 'px',
        width: activeSprite.width + 'px',
        overflowX: 'hidden',
        ...props.style,
      }}
      id={props.id}
      onClick={() => props.onClick()}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'black',
          transform: `translateX(${-x}px)`,
          transition: 'transform 200ms',
        }}>
        {props.sprites.map((sprite, index) => {
          return (
            <div
              key={`spriteButtonSprite-${index}`}
              style={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                height: props.height + 'px',
                paddingRight: '16px',
                ...THEME.f1,
              }}>
              <div>{sprite.text}</div>
            </div>
          )
        })}
      </div>
    </button>
  )
}

const Arrow = (props) => {
  return (
    <svg
      style={props.style}
      className="Arrow"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="white" />
    </svg>
  )
}

const DropdownButton = (props) => {
  return (
    <button
      className="DropdownButton"
      onClick={() => props.onClick()}
      style={props.style}>
      {props.text}
    </button>
  )
}

const Rule = (props) => {
  return <div class="Rule" style={props.style} />
}

export default Header
