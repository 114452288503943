const THEME = {
  header: {
    height: 100,
  },
  page: {
    margin: 50,
  },
  f1: {
    fontFamily: 'Libre Franklin, Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '29px',
  },
  f2: {
    fontFamily: 'Libre Franklin, Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0.02em',
  },
  subhead: {
    fontFamily: 'Libre Franklin, Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0.02em',
    lineHeight: '27px',
  },
  caption: {
    fontFamily: 'Libre Franklin, Arial',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '17px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  a: {
    textDecoration: 'none',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, [col-start] 1fr)',
    // gridAutoRows: 'auto',
    columnGap: '25px',
    // gridAutoFlow: 'row',
  },
  overlayColor: '#FF3D00',
  overlayColorMatrix: `1 0 0 0 0
    0 0.24 0 0 0
    0 0 0 0 0
    0 0 0 0 1
  `,
  list: {
    verticalMargin: 120,
    itemHeight: 60,
  },
  z: {
    xtelle: 650,
    aboutPage: 600,
    aboutButton: 800,
    menuButton: 200,
    menu: 100,
    arrow: 150,
    lightBox: 550,
    videoBackground: -1,
    photoBackground: -1,
  },
  xtelle: 10,
  // aboutColor: '#04311f',
  aboutColor: 'black',
}

export default THEME
