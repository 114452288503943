import React from 'react'
import Markdown from 'react-markdown'
import memoize from 'memoize-one'
import THEME from './theme'
import { useLockBodyScroll } from './lib'

const ContactItem = (props) => {
  return (
    <div>
      <div className={'aboutItemTitle'}>{props.title}</div>
      <span className={'aboutItemBody'}>
        <div className={'smallDash'} />
        <div className={'content'}>
          <p>{props.content}</p>
        </div>
      </span>
    </div>
  )
}

const makeList = (clientList) => {
  return <Markdown source={clientList} />
}

const parseInstagram = (inputString) => {
  if (inputString) {
    let username
    let handle
    if (inputString.charAt(0) === '@') {
      username = inputString.substr(1)
      handle = inputString
    } else {
      username = inputString
      handle = '@' + inputString
    }
    return <a href={`https://www.instagram.com/${username}/`}>{handle}</a>
  }
}

const returnEmail = (email) => {
  return (
    <a href={`mailto:${email}`} target={'_top'}>
      {email}
    </a>
  )
}

const About = (props) => {
  const contact = {
    email: props.bio.fields.email,
    phoneNumber: props.bio.fields.phoneNumber,
    instagram: props.bio.fields.instagram,
    representation: props.bio.fields.representation,
    representationCompany: props.bio.fields.representationCompany,
    production: props.bio.fields.production,
    productionCompany: props.bio.fields.productionCompany,
  }

  const representationHeading = contact.representationCompany
    ? `Representation – ${contact.representationCompany}`
    : 'Representation'

  const productionHeading = contact.productionCompany
    ? `Production Company – ${contact.productionCompany}`
    : 'Production Company'

  return (
    <div
      id="About"
      style={{
        transform: props.isOpen ? 'translateX(0px)' : 'translateX(110vw)',
      }}>
      <div id="AboutLayout">
        <div id="AboutBody">
          <div style={{ ...THEME.subhead }}>About</div>
          <Markdown source={props.bio.fields.biographyText} />
          <div style={{ ...THEME.subhead }}>Contact</div>
          <div>
            <ContactItem title={'Email'} content={returnEmail(contact.email)} />
            <ContactItem
              title={'Instagram'}
              content={parseInstagram(contact.instagram)}
            />
            {contact.representation !== '' ? (
              <ContactItem
                title={representationHeading}
                content={contact.representation}
              />
            ) : null}

            {contact.production !== '' ? (
              <ContactItem
                title={productionHeading}
                content={contact.production}
              />
            ) : null}

            <ContactItem
              title={'Clients'}
              content={makeList(props.bio.fields.clientList)}
            />

            <ContactItem
              title={'Exhibitions'}
              content={makeList(props.bio.fields.exhibitionsList)}
            />
            <ContactItem
              title={'Press'}
              content={makeList(props.bio.fields.pressList)}
            />
          </div>
        </div>
        <div
          id="AboutImage"
          style={{
            backgroundImage: `url(${props.bio.fields.portrait.fields.file.url})`,
            transform: props.isOpen ? 'translateX(0px)' : 'translateX(100vw)',
            transition: 'transform 500ms',
            position: 'relative',
          }}>
          {/* <div style={{ position: 'absolute', left: '16px', bottom: '16px' }}>
            <p
              style={{
                opacity: 0.66,
                fontFamily: 'Libre Franklin',
                color: 'white',
                fontSize: '14px',
              }}>
              Photo credit: Marielle Sales
            </p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default About
