import React, { Component } from 'react'
import * as contentful from 'contentful'
// import Footer from './components/Footer'
import VideoBackground from './components/VideoBackground'
import Xtelle from './components/Xtelle'
import Header from './components/Header'
import StickyList from './components/StickyList'
import About from './components/About'
import LightBox from './components/LightBox'
import PhotoBackground from './components/PhotoBackground'
import StaticAbout from './components/StaticAbout'
import {
  numericPredicate,
  STATES,
  PAGE_STATES,
  useLockBodyScroll,
  // BUTTON_STATES,
} from './components/lib'
import THEME from './components/theme'

export default class App extends Component {
  constructor(props) {
    super(props)
    // Contentful client
    this.client = contentful.createClient({
      // This is the space ID. A space is like a project folder in Contentful terms
      space: process.env.REACT_APP_SPACE_ID,
      // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      accessToken: process.env.REACT_APP_DELIVERY_ACCESS_TOKEN,
    })
    this.videoSectionRef = React.createRef()
    this.photoSectionRef = React.createRef()
    this.locked = false
    this.isTicking = false
    this.state = {
      bio: null,
      projects: [],
      photoProjects: [],
      motionProjects: [],
      init: STATES.LOADING,
      videoCursor: null,
      videoCursorActive: false,
      setPhotoCursor: 0,
      photoCursorActive: false,
      xtelle: false,
      menuOpen: false,
      pagePosition: PAGE_STATES.HOME,
      videoSectionStart: 0,
      photoSectionStart: 0,
      clientHeight: window.innerHeight,
      clientWidth: window.innerWidth,
      aboutOpen: false,
      lightBoxOpen: false,
      openProject: null,
      lightBoxIndex: 0,
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.runOnScroll = this.runOnScroll.bind(this)
    this.setVideoCursor = this.setVideoCursor.bind(this)
    this.setVideoCursorActive = this.setVideoCursorActive.bind(this)
    this.setPhotoCursor = this.setPhotoCursor.bind(this)
    this.setPhotoCursorActive = this.setPhotoCursorActive.bind(this)
    this.goToTop = this.goToTop.bind(this)
    this.goToFilms = this.goToFilms.bind(this)
    this.goToPhotos = this.goToPhotos.bind(this)
    this.setPagePosition = this.setPagePosition.bind(this)
    this.setSectionOffsets = this.setSectionOffsets.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.openAbout = this.openAbout.bind(this)
    this.closeAbout = this.closeAbout.bind(this)
    this.toggleAbout = this.toggleAbout.bind(this)
    this.openProject = this.openProject.bind(this)
    this.closeProject = this.closeProject.bind(this)
    this.largeXtelle = this.largeXtelle.bind(this)
    this.smallXtelle = this.smallXtelle.bind(this)
    this.nextInGallery = this.nextInGallery.bind(this)
    this.prevInGallery = this.prevInGallery.bind(this)
  }

  runOnScroll() {
    if (this.isTicking) return
    requestAnimationFrame(() => {
      this.handleScroll()
      this.isTicking = false
    })
    this.isTicking = true
  }

  async getContent() {
    try {
      // get the contentful space and all enties in the space
      const space = await this.client.getEntries()

      const splash = space.items.filter(
        (item) => item.sys.contentType.sys.id === 'splash',
      )[0]

      // filter out projects and sort
      const projects = space.items
        .filter((item) => item.sys.contentType.sys.id === 'project')
        .sort((a, b) =>
          numericPredicate(a.fields.indexOnMainPage, b.fields.indexOnMainPage),
        )

      // filter out the bio entry
      const bio = space.items.filter(
        (item) => item.sys.contentType.sys.id === 'biography',
      )[0]

      const photoProjects = projects
        .filter((item) => item.fields.type === 'Photography')
        .sort((a, b) =>
          numericPredicate(a.fields.indexOnMainPage, b.fields.indexOnMainPage),
        )

      const motionProjects = projects
        .filter((item) => item.fields.type === 'Motion')
        .sort((a, b) =>
          numericPredicate(a.fields.indexOnMainPage, b.fields.indexOnMainPage),
        )

      this.setState(
        {
          splash,
          bio,
          projects: projects,
          photoProjects,
          motionProjects,
          init: STATES.SUCCESS,
        },
        () => {
          this.handleResize()
        },
      )
    } catch (e) {
      console.error(e)
      this.setState({
        init: STATES.FAILURE,
      })
    }
  }

  componentDidMount() {
    this.getContent()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.runOnScroll)
  }

  handleResize() {
    this.setSectionOffsets()
    this.setState({
      clientHeight: window.innerHeight,
      clientWidth: window.innerWidth,
    })
  }

  setSectionOffsets() {
    this.setState({
      videoSectionStart:
        this.videoSectionRef.current.offsetTop + window.innerHeight / 2,
      photoSectionStart: this.photoSectionRef.current.offsetTop,
    })
  }

  handleScroll() {
    if (this.state.aboutOpen) return

    if (this.state.menuOpen === true) {
      this.setState({ menuOpen: false })
    }

    const y = window.scrollY

    if (y > THEME.xtelle) {
      if (this.state.xtelle === false && this.state.menuOpen === false) {
        this.setState({ xtelle: true })
        return
      }
    } else {
      if (this.state.xtelle === true && this.state.menuOpen === false) {
        this.setState({ xtelle: false })
        return
      }
    }

    if (y < this.state.videoSectionStart) {
      if (this.state.pagePosition !== PAGE_STATES.HOME) {
        this.setState({ pagePosition: PAGE_STATES.HOME })
        return
      }
    }

    if (y > this.state.videoSectionStart && y < this.state.photoSectionStart) {
      if (this.state.pagePosition !== PAGE_STATES.VIDEO) {
        this.setState({ pagePosition: PAGE_STATES.VIDEO })
        return
      }
    }

    if (y > this.state.photoSectionStart) {
      if (this.state.pagePosition !== PAGE_STATES.PHOTO) {
        this.setState({ pagePosition: PAGE_STATES.PHOTO })
        return
      }
    }
  }

  setVideoCursor(index) {
    this.setState({ videoCursor: index, videoCursorActive: true })
  }

  setVideoCursorActive(val) {
    this.setState({ videoCursorActive: val })
  }

  setPhotoCursor(index) {
    this.setState({ photoCursor: index, photoCursorActive: true })
  }

  setPhotoCursorActive(val) {
    this.setState({ photoCursorActive: val })
  }

  setRef(key, value) {
    this[key] = value
  }

  goToTop() {
    window.scrollTo(0, 0)
    this.setPagePosition(PAGE_STATES.HOME)
    this.largeXtelle()
  }

  goToFilms() {
    window.scrollTo(0, this.state.videoSectionStart + 1)
    this.setPagePosition(PAGE_STATES.VIDEO)
    this.smallXtelle()
  }

  goToPhotos() {
    window.scrollTo(0, this.state.photoSectionStart + 1)
    this.setPagePosition(PAGE_STATES.PHOTO)
    this.smallXtelle()
  }

  setPagePosition(value) {
    this.setState({ pagePosition: value })
  }

  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  toggleMenu() {
    this.state.menuOpen ? this.closeMenu() : this.openMenu()
  }

  openAbout() {
    this.setState({ aboutOpen: true, xtelle: true })
  }

  closeAbout() {
    this.setState({
      aboutOpen: false,
      xtelle: window.scrollY > THEME.xtelle ? true : false,
    })
  }

  toggleAbout() {
    this.state.aboutOpen ? this.closeAbout() : this.openAbout()
  }

  largeXtelle() {
    this.setState({ xtelle: false })
  }

  smallXtelle() {
    this.setState({ xtelle: true })
  }

  openProject(project) {
    this.setState({
      lightBoxOpen: true,
      openProject: project,
    })
  }

  closeProject() {
    this.setState({
      openProject: null,
      lightBoxIndex: 0,
      lightBoxOpen: false,
    })
  }

  nextInGallery() {
    if (
      this.state.lightBoxIndex ===
      this.state.openProject.fields.assets.length - 1
    )
      return
    this.setState({ lightBoxIndex: this.state.lightBoxIndex + 1 })
  }

  prevInGallery() {
    if (this.state.lightBoxIndex === 0) return
    this.setState({ lightBoxIndex: this.state.lightBoxIndex - 1 })
  }

  isLastInLightbox() {
    if (this.state.openProject) {
      return (
        this.state.lightBoxIndex ===
        this.state.openProject.fields.assets.length - 1
      )
    }
    return false
  }

  isFirstInLightbox() {}

  render() {
    const { state } = this

    if (this.state.lightBoxOpen === true || this.state.aboutOpen === true) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    if (state.init === STATES.SUCCESS) {
      return (
        <div>
          <Header
            toggleMenu={this.toggleMenu}
            menuOpen={state.menuOpen}
            goToTop={this.goToTop}
            goToFilms={this.goToFilms}
            goToPhotos={this.goToPhotos}
            pagePosition={state.pagePosition}
            openAbout={this.openAbout}
            closeAbout={this.closeAbout}
            toggleAbout={this.toggleAbout}
            closeProject={this.closeProject}
            aboutOpen={this.state.aboutOpen}
            lightBoxOpen={this.state.lightBoxOpen}
            aboutPosition={
              state.aboutOpen
                ? 'CLOSE_ABOUT'
                : state.lightBoxOpen
                ? 'CLOSE_LIGHTBOX'
                : 'ABOUT'
            }
          />

          <LightBox
            isOpen={state.lightBoxOpen}
            project={state.openProject}
            lightBoxIndex={state.lightBoxIndex}
            nextInGallery={this.nextInGallery}
            prevInGallery={this.prevInGallery}
            isLast={this.isLastInLightbox()}
            isFirst={this.state.lightBoxIndex === 0}
          />

          {
            // Start Video section
          }
          <div ref={this.videoSectionRef}>
            <VideoBackground
              style={{
                transform: this.state.aboutOpen
                  ? 'translateX(-100vw)'
                  : 'translateX(0px)',
              }}
              splash={state.splash}
              projects={state.motionProjects}
              pagePosition={state.pagePosition}
              videoCursor={state.videoCursor}
              videoCursorActive={state.videoCursorActive}
            />

            <StickyList
              cursor={state.videoCursor}
              cursorActive={state.videoCursorActive}
              setCursor={this.setVideoCursor}
              setCursorActive={this.setVideoCursorActive}
              header={'Selected Films'}
              projects={state.motionProjects}
              openProject={this.openProject}
            />
          </div>
          {
            // End Video section
            // Start Photo section
          }
          <div
            id="PhotoSection"
            style={{
              transform: this.state.aboutOpen
                ? 'translateX(-100vw)'
                : 'translateX(0px)',
            }}
            ref={this.photoSectionRef}>
            <PhotoBackground
              load={process.env.NODE_ENV === 'production'}
              cursor={state.photoCursor}
              cursorActive={state.photoCursorActive}
              projects={state.photoProjects}
            />
            <StickyList
              cursor={state.photoCursor}
              cursorActive={state.photoCursorActive}
              setCursor={this.setPhotoCursor}
              setCursorActive={this.setPhotoCursorActive}
              header={'Selected Photography'}
              projects={state.photoProjects}
              openProject={this.openProject}
            />
          </div>
          <About isOpen={state.aboutOpen} bio={state.bio} />

          <Xtelle
            retract={state.xtelle}
            clientWidth={state.clientWidth}
            clientHeight={state.clientHeight}
          />

          {
            // End Photo section
          }
        </div>
      )
    } else if (state.init === STATES.FAILURE) {
      return <div>Failed to load</div>
    } else {
      return <div>Loading...</div>
    }
  }
}
